@import "@carbon/colors";
@import "../../colours";
.header {
  .title {
    font-family: "Bellefair", serif;
    color: $cool-gray-70;
    font-size: 3rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .subtitle {
    font-family: "Bellefair", serif;
    color: $cool-gray-70;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
  }
  .date {
    font-family: "Crimson Pro", serif;
    color: #4d5358;
    font-size: 1.75rem;
    letter-spacing: 0.0725rem;
    margin-top: 1%;
    margin-bottom: 1%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-weight: 100;
  }
}
