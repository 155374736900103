// App.scss

@use "@carbon/react/scss/themes";
@use "@carbon/react/scss/theme" with (
  $theme: themes.$g100
);

@import "@carbon/react";
@import "@carbon/colors";
@import "colours";

$primary-color: #1976d2;

body {
  font-family: "Roboto", sans-serif;
}

h1 {
  color: $primary-color;
}

.button {
  background-color: $primary-navy-color !important;
  color: $text-white-color !important;
}

.center {
  justify-content: center;
  // display: flex;
  text-align: center;
}
.content {
  min-height: 100vh;
  // display: flex;
  // background-color: url("https://img.freepik.com/free-photo/paper-texture_1194-5999.jpg?w=2000&t=st=1700772322~exp=1700772922~hmac=c5d109fa60a3c616b227f43dc84506db2c308ca75db6afde10932d823ffadd91");
}
