@mixin responsive-font($size) {
  font-size: $size;

  @media (max-width: 768px) {
    font-size: ($size * 0.55);
  }
}

// Mixin for responsive margin top
@mixin responsive-margin-top($margin) {
  margin-top: $margin;

  @media (max-width: 768px) {
    margin-top: ($margin * 0.25);
    display: block;
  }
}

.footer {
  margin-top: -4%;
  height: 1.25rem;
  @include responsive-font(0.675rem);
}

.our-story {
  .story-card {
    width: 90%;
    height: 90%;
    .story {
      .image {
        width: 60%;
      }
      .story-details {
        width: unset;
        .location,
        .text,
        .date {
          @include responsive-font(2rem);
        }
      }
    }
  }
}

.slide {
  margin-right: 30px;
}

.overlay-container {
  max-width: 90%;
  left: 10%;

  .wedding-info {
    @include responsive-margin-top(47vh);
    border-radius: 10px 10px 0px 0px;
    .title {
      @include responsive-font(4rem);
      letter-spacing: 0.2rem;
      // text-transform: uppercase;
    }
    .subtitle {
      @include responsive-font(2.5rem);
      margin-top: 1%;
    }
    .button-group {
      .button {
        height: responsive-font(3rem);
        font-size: responsive-font(1.125rem);
      }
    }
  }
}

// Media Queries for responsiveness
@media (max-width: 768px) {
  .header {
    .title {
      @include responsive-font((3rem));
    }
    .subtitle {
      @include responsive-font((1.5rem));
    }
    .date {
      @include responsive-font((1.75rem));
    }
  }
  .footer {
    margin-top: 0;
    height: auto;
    @include responsive-font(1rem); // Adjust as needed
  }

  .overlay-container {
    @include responsive-margin-top(47vh);
    left: 0;

    .wedding-info {
      margin-top: 20%; // Adjust as needed
    }
  }

  .slide {
    margin-right: 0;
  }

  .our-story {
    .story-card {
      .story {
        .story-details {
          .location,
          .date {
            @include responsive-font(1rem);
          }
        }
      }
    }
  }
  .rsvp {
    .slideshow-container {
      display: none;
    }
    .rsvp-form-container {
      width: 100%;
      padding-bottom: 10%;
    }
    .view {
      display: flex;
      flex-direction: column !important;
    }
    .search {
      padding: 10% 0% !important;
    }
  }

  .details-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 10% 0;
    .details {
      .header {
        line-height: 1.5;
      }
      .details-content {
        .timeline-tab {
          width: -webkit-fill-available !important;
        }
        .faqs-tab {
          .qa-container {
            .qa {
              .answer-container {
                .answer {
                  margin-top: 5%;
                }
              }
            }
          }

          .logo {
            width: 13rem !important;
            margin: auto;
            display: block;
          }
        }
        .event-section {
          display: flex;
          width: inherit;
          .event {
            width: inherit;
            .event-location {
              width: inherit;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              align-content: center;
              justify-content: center;
              align-items: center;
              .location-image-container {
                width: inherit !important;
                height: unset !important;
              }
              .location-details {
                .location {
                  .event-map {
                    height: unset !important;
                    margin-top: 2%;
                    width: inherit !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
