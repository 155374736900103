.qa-container {
  .qa {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    .question {
      font-family: "Bellefair", serif;
      color: #4d5358;
      font-size: 1.5rem;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 2%;
    }
    .answer-container {
      display: flex;
      .answer {
        font-family: "Crimson Pro", serif;
        font-size: 1.25rem;
        color: #4d5358;
        font-style: italic;
        font-weight: 100;
        margin-top: 1%;
        text-align: center;
      }
    }
  }
}
