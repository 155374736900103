@import "@carbon/colors";
@import "../../colours";
$transition-duration: 0.8s;

.invitation-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background: rgb(44 44 44);
  .invitation-frame {
    width: 90vw;
    height: 90vh;
    .mdl-grid--layout-row {
      display: none;
    }
    #desktop-bottomsheet {
      display: none !important;
    }
  }
}

.homepage {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $gray-100;
  // filter: grayscale(1);
  .homepage-content {
    display: inline-flexbox;
    position: relative;
    z-index: 1;

    .slideshow-container {
      width: 100%;
      height: 100vh;
      overflow: hidden;

      .slide {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        position: absolute;
        opacity: 0;
        transition: opacity $transition-duration;

        &.active {
          opacity: 1;
        }
      }
    }

    .overlay-container {
      position: absolute;
      left: 5%;
      text-align: center;

      .wedding-info {
        margin-top: 50vh;
        position: relative;
        z-index: 1000;
        background-color: $primary-navy-color;
        border-radius: 10px;
        border: 2px solid $primary-border-color;
        .title {
          font-family: "Pinyon Script", cursive;
          color: $text-white-color;
          letter-spacing: 0.2rem;
        }
        .subtitle {
          font-family: "Bellefair", serif;
          color: $text-white-color;
          // font-size: 2rem;
          text-transform: uppercase;
          margin-top: 1%;
        }
        .flip-countdown {
          text-align: left;
          .flip-countdown-piece {
            .flip-countdown-title {
              color: $white;
            }
            .flip-countdown-card .flip-countdown-card-sec .card__top {
              border-bottom: solid 1px $gray-20 !important;
            }
          }
        }
      }
      .button-group {
        .button {
          background-color: $primary-border-color !important;
          color: $primary-navy-color !important;
          // height: 3rem;
          // font-size: 1.125rem;
        }
      }
    }
  }
}

.rsvp {
  width: 100vw;
  height: inherit;
  display: flex;
  overflow-y: hidden;
  padding-bottom: 10% !important;
  .slideshow-container {
    width: 50%;
    background-color: $primary-navy-color;
    .rsvp-image {
      width: 50%;
      position: fixed;
      z-index: 1000;
      transform: translate(3%, 3%);
    }
  }
  .rsvp-form-container {
    width: 50%;
    padding: 2%;

    .wedding-info {
      // background-image: url(https://img.glyphs.co/img?q=85&w=900&src=aHR0cHM6Ly9zMy5tZWRpYWxvb3QuY29tL2Jsb2ctaW1hZ2VzL2dydWJieS13YXRlcmNvbG91ci1wYXBlci10ZXh0dXJlLXBob3Rvc2hvcC1wc2RzLmpwZz9tdGltZT0yMDE3MDcxMjE2MzgxOA==);
      // margin-top: 50vh;
      position: relative;
      // z-index: 1000;
      border-radius: 10px;
      // opacity: 0.75;
      background-color: $white;
    }
    .button-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    // .MuiButtonGroup-root.MuiButtonGroup-outlined.button-group.css-edsu1d-MuiButtonGroup-root {
    //   margin-left: 10vw;
    //   filter: grayscale(1);
    // }
  }
}

.slideshow-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    opacity: 0;
    transition: opacity 0.8s;

    &.active {
      opacity: 1;
    }
  }
}

.our-story {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #161616;
  background-size: cover;
  background-image: url(https://t4.ftcdn.net/jpg/05/71/11/73/360_F_571117331_DVNWLMbxxB8sql1IhuHV3hHqxqKKv2Xx.jpg);
  .story-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    background-image: url(https://img.glyphs.co/img?q=85&w=900&src=aHR0cHM6Ly9zMy5tZWRpYWxvb3QuY29tL2Jsb2ctaW1hZ2VzL2dydWJieS13YXRlcmNvbG91ci1wYXBlci10ZXh0dXJlLXBob3Rvc2hvcC1wc2RzLmpwZz9tdGltZT0yMDE3MDcxMjE2MzgxOA==);
    border-radius: 10px;
    opacity: 0.75;
    width: 70%;
    // height: 70%;
    height: -webkit-fill-available;
    clip-path: polygon(
      3% 0,
      7% 1%,
      11% 0%,
      16% 2%,
      20% 0,
      23% 2%,
      28% 2%,
      32% 1%,
      35% 1%,
      39% 3%,
      41% 1%,
      45% 0%,
      47% 2%,
      50% 2%,
      53% 0,
      58% 2%,
      60% 2%,
      63% 1%,
      65% 0%,
      67% 2%,
      69% 2%,
      73% 1%,
      76% 1%,
      79% 0,
      82% 1%,
      85% 0,
      87% 1%,
      89% 0,
      92% 1%,
      96% 0,
      98% 3%,
      99% 3%,
      99% 6%,
      100% 11%,
      98% 15%,
      100% 21%,
      99% 28%,
      100% 32%,
      99% 35%,
      99% 40%,
      100% 43%,
      99% 48%,
      100% 53%,
      100% 57%,
      99% 60%,
      100% 64%,
      100% 68%,
      99% 72%,
      100% 75%,
      100% 79%,
      99% 83%,
      100% 86%,
      100% 90%,
      99% 94%,
      99% 98%,
      95% 99%,
      92% 99%,
      89% 100%,
      86% 99%,
      83% 100%,
      77% 99%,
      72% 100%,
      66% 98%,
      62% 100%,
      59% 99%,
      54% 99%,
      49% 100%,
      46% 98%,
      43% 100%,
      40% 98%,
      38% 100%,
      35% 99%,
      31% 100%,
      28% 99%,
      25% 99%,
      22% 100%,
      19% 99%,
      16% 100%,
      13% 99%,
      10% 99%,
      7% 100%,
      4% 99%,
      2% 97%,
      1% 97%,
      0% 94%,
      1% 89%,
      0% 84%,
      1% 81%,
      0 76%,
      0 71%,
      1% 66%,
      0% 64%,
      0% 61%,
      0% 59%,
      1% 54%,
      0% 49%,
      1% 45%,
      0% 40%,
      1% 37%,
      0% 34%,
      1% 29%,
      0% 23%,
      2% 20%,
      1% 17%,
      1% 13%,
      0 10%,
      1% 6%,
      1% 3%
    );

    .story-header {
      margin-top: 2%;
      .title {
        font-family: "Bellefair", serif;
        color: $cool-gray-70;
        font-size: 3rem;
        letter-spacing: 0.2rem;
        text-transform: uppercase;

        .dot {
          circle {
            margin-top: -5%;
          }
        }
      }

      .subtitle {
        font-family: "Bellefair", serif;
        color: $gray-70;
        font-size: 2rem;
        margin-top: 1%;
      }
    }

    .timeline {
      position: relative;
    }

    .story {
      position: absolute;
      width: 100%;
      opacity: 0;
      transition: opacity 0.5s;
      display: flex;

      &.active {
        opacity: 1;
      }

      &.side-switch {
        flex-direction: row-reverse !important;
      }

      .image {
        // width: 50%;
        // width: 50rem;
        width: 35rem;
        opacity: 0.9;
      }
      .story-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
        width: 80rem;
        .headers {
          font-family: "Bellefair", serif;
          color: $gray-70;
          font-size: 2rem;
          display: flex;
          align-content: center;
          align-items: center;
          margin: 10%;
        }
        .text {
          font-family: "Bellefair", serif;
          color: $gray-70;
          font-size: 1.5rem;
          display: flex;
          align-content: center;
          align-items: center;
          margin: 1%;
        }
        .location {
          color: $gray-70;
          // font-size: 1.5rem;
          display: flex;
          align-content: center;
          align-items: center;
          padding-top: 5%;
          text-transform: uppercase;
          letter-spacing: 3px;
        }
        .date {
          color: $gray-70;
          // font-size: 1.75rem;
          display: flex;
          align-content: center;
          align-items: center;
          margin-top: 2%;
          text-transform: uppercase;
          letter-spacing: 3px;
        }
      }
    }
  }
}

/* Add responsive styles for mobile devices */

.demoPage {
  background-color: black;
  color: wheat;
  img {
    width: 10rem;
  }
}

.details-container {
  width: 100vw;
  height: inherit;
  margin: 2%;
  .details {
    padding: 2%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .details-content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      width: 90vw !important;
      .cds--tabs {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        .cds--tabs__nav-item-label-wrapper {
          position: relative;
          inset-block-start: 0.0625rem;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          align-content: center;

          .cds--tabs__nav-item-label {
            font-family: "Bellefair", serif;
            color: #4d5358;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  // border: 1px solid #000080;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  max-height: 34rem;
}

// .content {
//   flex: 1;
// }

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.location,
.address,
.date-time {
  margin-bottom: 10px;
}

.btn {
  background-color: #000080;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.image-overlay {
  position: absolute;
  top: 2%;
  left: 60%;
  width: 40%;
  height: 92%;
  /* z-index: -1; */
}

.image {
  position: absolute;
  width: 100%;
  height: auto;
  display: block;
  margin-top: 10%;
  margin-left: -10%;
}

.image-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000080;
}

.vertical-line {
  width: 1px;
  height: 100px;
  background-color: lightgray;
  margin: 3%;
  transform: rotate(180deg);
}
