.search {
  padding: 5% 0%;
  .search-input {
    .MuiInputBase-input {
      font-family: "Bellefair", serif !important;
      color: #4d5358;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
  }
  .search-results-title {
  }
  .search-results {
    background: unset;
    border-radius: 0px 0px 5px 5px;
    border-left: 0.5px solid gray;
    border-right: 0.5px solid gray;
    border-bottom: 0.5px solid gray;
    font-family: "Bellefair", serif;
    text-transform: uppercase;
    color: #4d5358;
    font-size: 1.25rem;
    display: flex;
    flex-wrap: wrap;

    .invitees {
      // display: flex;
    }
  }
}
