@import "@carbon/colors";
@import "../../colours";
.footer {
  height: 1.25rem;
  position: relative;
  z-index: 1000;
  // background-image: url(https://img.glyphs.co/img?q=85&w=900&src=aHR0cHM6Ly9zMy5tZWRpYWxvb3QuY29tL2Jsb2ctaW1hZ2VzL2dydWJieS13YXRlcmNvbG91ci1wYXBlci10ZXh0dXJlLXBob3Rvc2hvcC1wc2RzLmpwZz9tdGltZT0yMDE3MDcxMjE2MzgxOA==);
  background-color: $primary-navy-color;
  display: flex;
  align-content: space-around;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: -1%;
  font-family: "Crimson Pro", serif;
  color: $text-white-color;
}

@media (max-width: 768px) {
  .footer {
    margin-top: -4%;
    height: 1.25rem;
    font-size: 0.675rem;
  }
}

.header {
  // padding-bottom: 3rem;
  .cds--header {
    background-color: $gray-50;
    color: $white;
    font-family: "Bellefair", serif;
    border: none;
    .cds--header__name--prefix {
      font-weight: 400;
      display: none;
    }
    .header-name {
      .site-logo {
        max-height: 2rem;
        max-width: 7rem;
      }
    }
    .header-menu-item {
      margin-top: -1rem;
    }

    .cds--popover {
      visibility: hidden !important;
    }
  }
  .countdown-timer {
    color: $white;
  }
  .button {
    filter: grayscale(1);
    background-color: $cool-gray-70;
    margin-top: 4%;
  }
}
