@import "../../../colours.scss";
@import "@carbon/colors";
.timeline-tab-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
}

.images {
  background-color: $primary-navy-color;
  .details-image {
    width: 37rem;
    transform: translate(10%, 5%);
  }
}
.timeline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .timeline {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 600px;

    .event {
      flex: 1;
      padding: 20px;

      .event-title {
        font-size: 24px;
        margin-bottom: 10px;
      }

      .event-details {
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 20px;
      }

      .event-description {
        font-style: italic;
      }
    }

    .separator {
      border-left: 2px dashed #000;
      height: 100%;
      margin: 0 20px;
    }
  }
}
.timeline-tab {
  //   width: 80%;
  width: 100%;
  .notes {
    font-family: "Crimson Pro", serif !important;
    font-size: 1rem !important;
    color: $red-70;
    font-style: italic;
    text-align: unset !important;
    width: 100% !important;
    margin-bottom: unset !important;
    font-weight: 100;
    display: flex !important;
    font-weight: 100;
    justify-content: center !important;
  }
  .timeline {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .event {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 2%;

      .date-header {
        font-family: "Bellefair", serif;
        color: #4d5358;
        font-size: 2rem;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 10%;
      }
      .event-header {
        font-family: "Bellefair", serif;
        color: #4d5358;
        font-size: 1.5rem;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 2%;
      }
      .event-location {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        line-height: 1.5;
        .location-image-container {
          img.location-image {
            width: 15rem !important;
            // }
          }
        }
        .location-details {
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
          .event-address {
            font-family: "Crimson Pro", serif;
            font-size: 1.5rem;
            color: #4d5358;
            font-style: italic;
            margin-top: 1%;
          }
          .event-body {
            font-family: "Crimson Pro", serif;
            font-size: 1rem;
            color: #4d5358;
            font-style: italic;
            font-weight: 100;
          }
          .event-time {
            font-family: "Bellefair", serif;
            color: #4d5358;
            font-size: 1rem;
            margin-top: 1%;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
    }
  }
}

.faqs-tab {
  width: inherit;
  .slick-arrow {
    background-color: transparent !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: $primary-navy-color !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .faqs {
    padding: 2%;
    .qa-container {
      width: inherit;
      // padding: 4% 0;
      .qa {
        width: inherit;
        .answer-container {
          width: inherit;
          .answer {
            width: inherit !important;
            line-height: 1.5;
          }
        }
      }
    }
  }
  .logo {
    width: 20rem;
    margin: auto;
    display: block;
  }
}
.event-section {
  display: flex;
  .event {
    .event-header {
      font-family: "Bellefair", serif;
      color: #4d5358;
      font-size: 1.5rem;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      display: flex;
      margin-top: 2%;
      align-items: center;
      justify-content: center;
    }
    .event-location {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      .location-image-container {
        width: 40rem;
        height: 40rem;

        .location-image {
          width: inherit;
          height: inherit;
        }
      }
      .location-details {
        display: flex;
        width: inherit;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        padding: 2%;
        .location {
          padding: 2%;
          line-height: 1.25;
          .event-address {
            font-family: "Crimson Pro", serif;
            font-size: 1.5rem;
            color: #4d5358;
            font-style: italic;
            //   margin-top: 1%;
          }
          .event-body {
            font-family: "Crimson Pro", serif;
            font-size: 1rem;
            color: #4d5358;
            font-style: italic;
            font-weight: 100;
            margin-bottom: 0.5%;
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
          }
          .event-map {
            height: 15rem;
            margin-top: 2%;
          }
          .event-time {
            font-family: "Bellefair", serif;
            color: #4d5358;
            font-size: 1rem;
            //   margin-top: 1%;
            text-transform: uppercase;
            margin-bottom: 0.5%;
          }
        }
        .event-details {
          padding: 2%;
          .label-header {
            font-family: "Bellefair", serif;
            color: #4d5358;
            font-size: 1.25rem;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
            display: flex;
            margin-top: 2%;
          }
          .label-description {
            font-family: "Crimson Pro", serif;
            font-size: 1rem;
            color: #4d5358;
            font-style: italic;
            font-weight: 100;
            margin-bottom: 0.5%;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
