@import "@carbon/colors";
@import "../../colours";
form {
  font-family: "Open Sans", sans-serif;
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

label {
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

label span.error {
  color: $red-60;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

input,
select,
textarea {
  padding: 0.5rem;
  border: 1px solid $gray-10;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

input[type="submit"] {
  background-color: $green-50;
  color: $white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.rsvp-form {
  .rsvp-title {
    font-family: "Bellefair", serif;
    color: $cool-gray-70;
    font-size: 3rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .rsvp-subtitle {
    font-family: "Bellefair", serif;
    color: $cool-gray-70;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
  }
  .rsvp-date {
    font-family: "Crimson Pro", serif;
    color: $cool-gray-70;
    font-size: 1.75rem;
    letter-spacing: 0.0725rem;
    margin-top: 1%;
    margin-bottom: 1%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-weight: 100;
  }
  .rsvp-form-outer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 5%;
    margin: 1%;
  }
  .rsvp-form-questions {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100%;

    .confirmation {
      .title {
        font-family: "Bellefair", serif;
        color: $cool-gray-70;
        font-size: 1.5rem;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
      }
      .description {
        font-family: "Crimson Pro", serif;
        font-size: 1.25rem;
        color: $cool-gray-70;
        font-style: italic;
        margin-top: 1%;
        text-align: center;
      }

      .guests {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        padding: 2% 0;
        .guest {
          margin: 2%;
          padding: 2%;
          width: 80%;
          .name {
            font-family: "Bellefair", serif;
            color: #4d5358;
            font-size: 1.5rem;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
          }
          .option {
            font-family: "Crimson Pro", serif;
            font-size: 1rem;
            padding: 1% 2%;
          }
        }
      }
    }
    .meal-options-container {
      padding: 5% 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .meal-option-tile {
        display: flex;
        align-items: center;
        margin: 2%;
        width: inherit;
        flex-wrap: nowrap;
        flex-direction: row;

        .details {
          .name {
            font-family: "Bellefair", serif;
            color: $cool-gray-70;
            font-size: 1.125em;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
          }
          .description {
            font-family: "Crimson Pro", serif;
            font-size: 1rem;
          }
        }
        .option-tile-image {
          width: 5rem;
          height: 5rem;
          padding: 1%;
        }
      }
    }
    .label {
      font-family: "Bellefair", serif;
      color: $cool-gray-70;
      font-size: 1.125rem;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    .view {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      align-content: center;
      text-align: center;
      align-items: center;
      margin-bottom: 2%;
      .ceremony-image {
        width: 30%;
      }
      .advice-image {
        width: 70%;
      }
      .details {
        padding: 5%;
        .title {
          font-family: "Crimson Pro", serif;
          font-size: 1.25rem;
          color: $cool-gray-70;
          font-style: italic;
          margin-top: 1%;
        }
        .address {
          font-family: "Crimson Pro", serif;
          font-size: 1rem;
          color: #4d5358;
          font-style: italic;
          font-weight: 100;
        }
        .time {
          font-family: "Bellefair", serif;
          color: $cool-gray-70;
          font-size: 1rem;
          margin-top: 1%;
          text-transform: uppercase;
        }
      }
    }
    .notes {
      font-family: "Crimson Pro", serif;
      font-size: 1.125rem;
      color: $red-70;
      font-style: italic;
      text-align: center;
      width: 70%;
      margin-bottom: 2%;
      font-weight: 100;
    }

    .attendance {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      width: inherit;
      .text {
        width: -webkit-fill-available;
      }
      .guest {
        align-content: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5% 0px;
        width: -webkit-fill-available;
        .diet-options {
          width: 15rem;
          margin-top: 2%;
        }
        .meal-options {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: nowrap;

          .selected {
            background-color: $primary-border-color;
          }
          .meal-option {
            margin: 5%;
            input {
              display: none;
            }
            .option-image {
              width: 4rem;
            }
          }
        }
      }
      .name {
        align-items: center;
        color: #4d5358;
        display: flex;
        font-family: Bellefair, serif;
        justify-content: center;
        letter-spacing: 0.2rem;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 2%;
      }
      .button-group {
        .form-button {
          color: $primary-navy-color !important;
          outline: none !important;
          border: 1px solid $primary-navy-color !important;
        }
        .selected {
          background-color: $primary-navy-color !important;
          color: $text-white-color !important;
        }
      }

      .form-input {
        display: flex;
        flex-direction: row;
        .dotted-radio {
          & .MuiSvgIcon-root {
            border: 0.25px dotted $cool-gray-70;
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
          }

          &.Mui-checked {
            & .MuiSvgIcon-root {
              border: 1px dotted $cool-gray-90;
            }
          }
        }
        .MuiFormControlLabel-label {
          font-family: "Bellefair", serif;
          color: $cool-gray-70;
          font-size: 1rem;
          letter-spacing: 0.2rem;
          text-transform: uppercase;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          margin-top: 5%;
        }
      }
    }
  }

  .final-message {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    .title {
      font-family: "Bellefair", serif;
      color: $cool-gray-70;
      font-size: 1.5rem;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 2%;
    }
    .description {
      font-family: "Crimson Pro", serif;
      color: $cool-gray-70;
      font-size: 1.75rem;
      letter-spacing: 0.0725rem;
      margin-top: 1%;
      margin-bottom: 5%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-style: italic;
      font-weight: 100;
    }
    .final-img {
      width: 50%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 2%;
    }
    .subtitle {
      font-family: "Crimson Pro", serif;
      color: $cool-gray-70;
      font-size: 1rem;
      letter-spacing: 0.0725rem;
      margin-top: 5%;
      margin-bottom: 5%;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-style: italic;
      font-weight: 100;
      border-radius: 10px;
    }
    .calendar {
    }
  }

  .form-buttons-container {
    .form-button {
      color: $primary-navy-color !important;
    }
  }

  .rsvp-step,
  .typo,
  .MuiStepLabel-label,
  .MuiFormLabel-root {
    font-family: "Bellefair", serif !important;
  }
  .MuiStepLabel-label {
    font-size: 1.5rem;
  }
}
.users {
  display: flex;
}
