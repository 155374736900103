.hotel-container {
  width: inherit;
  .hotel-tab {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    .event-section {
      .event {
        .event-header {
          width: inherit;
          display: flex !important;
          flex-direction: column;
          align-content: center;
          align-items: center;
          text-align: center;
          .event-buttons,
          .MuiButtonGroup-outlined {
            padding: 1%;
          }
        }
        .event-location {
          .location-image-container {
            .location-image {
            }
          }
          .location-details {
            .location {
              margin-bottom: unset;
              .event-address {
              }
            }
            .event-details {
            }
          }
        }
        .event-map {
          width: -webkit-fill-available;
          margin: 5% 0;
        }
        .notes {
          font-family: "Crimson Pro", serif;
          font-size: 1.125rem;
          color: red;
          font-style: italic;
          margin-bottom: 2%;
          font-weight: 100;
          width: inherit;
        }
      }
    }
  }
}
.flight-container {
  width: inherit;
  .flight-information {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    .event-section {
      .event {
        .event-header {
          width: inherit;
          display: flex !important;
          flex-direction: column;
          align-content: center;
          align-items: center;
          text-align: center;
          .event-buttons,
          .MuiButtonGroup-outlined {
            padding: 1%;
          }
        }
        .event-location {
          .location-image-container {
            .location-image {
            }
          }
          .location-details {
            .location {
              margin-bottom: unset;
              .event-address {
                .event-body {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
            .event-details {
            }
          }
        }
        .event-map {
          width: -webkit-fill-available;
          margin: 5% 0;
        }
        .notes {
          font-family: "Crimson Pro", serif;
          font-size: 1.125rem;
          color: red;
          font-style: italic;
          margin-bottom: 2%;
          font-weight: 100;
          width: inherit;
        }
      }
    }
  }
}
